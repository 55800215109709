<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <XyzTransition
        appear
        xyz="fade down-3 delay-6"
      >
        <v-card class="auth-card">
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                class="me-3 "
              />

              <v-img
                :src="require('@/assets/images/svg/oriens-text.svg')"
                max-width="130px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"
              />
            </router-link>
          </v-card-title>

          <div>
            <v-tabs-items
              v-model="currentTab"
            >
              <v-tab-item>
                <v-card-text class="text-center">
                  <p class="text-subtitle-1 font-weight-semibold text--primary mb-2">
                    Buat Password Baru 🔒
                  </p>
                  <p class="mb-2">
                    Buat password baru untuk akunmu.
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-4"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    />
                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isPasswordConfirmVisible ? 'text' : 'password'"
                      label="Konfirmasi Password"
                      placeholder="Konfirmasi Password"
                      :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-4"
                      :rules="[ confirmedValidator(confirmPassword, password) ]"
                      @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                    />

                    <v-btn
                      block
                      color="primary"
                      :loading="loading"
                      @click="currentTab = 1"
                    >
                      Buat Password Baru
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="d-flex flex-column align-center">
                  <span class="text-2xl mb-4 font-medium">Berhasil!</span>
                  <span class="text-center text-subtitle-1 mb-2">
                    Berhasil membuat password baru!
                  </span>
                  <span class="text-center text-caption">
                    Anda akan diarahkan ke dashboard dalam 3...
                  </span>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </XyzTransition>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <XyzTransition
      appear
      xyz="fade left-3"
    >
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      />
    </XyzTransition>

    <!-- tree  -->
    <XyzTransition
      appear
      xyz="fade right-3 delay-3"
    >
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      />
    </XyzTransition>
  </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { confirmedValidator } from '@core/utils/validation'

export default {
  setup() {
    const loading = ref(false)
    const currentTab = ref(0)

    const password = ref('')
    const confirmPassword = ref('')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    return {
      loading,
      currentTab,

      password,
      confirmPassword,
      isPasswordVisible,
      isPasswordConfirmVisible,

      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      confirmedValidator,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
